import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MyWork = () => (
  <Layout>
    <SEO title="My Work" />
    <h1>My Work</h1>
    
    <h5>Lighthouse Labs</h5>
    <p>    
      I work as a mentor (top-ranked based on student feedback surveys!) at Lighthouse Labs, where students learn to become a developer in an intensive  10-week full-time program. I help students one-on-one with programming/debugging, give live-coding lectures, run code reviews, and mock interviews.
      Some of the tech in the cirriculum: Express.js, React.js, Ruby on Rails, Git, SQL, and anything the student wants to use for their group projects.
    </p>
    <div style={{textAlign: "center"}}>
      <img style={{maxWidth: "700px"}} src="/lighthouse.jpg" />
    </div>

    <h5>Charmed Aroma</h5>
    <p>
      I handle the tech for Charmed Aroma, a Shopify Plus enterprise-scale multi-national ecommerce client. Some projects include:
    </p>
    <ul>
      <li>End-to-end software from creation to fulfillment of end-user created personalized products. Main components include image manipulation using Python Pillow, end-user React UI for product customization, and soft real-time Shopify-integrated order dashboard for fulfillment, using Elixir/Phoenix.</li>
      <li>Internal-facing APIs built with Phoenix & Elixir, AWS Lambda, for shipping estimation, ring appraisal database, support system integration,...</li>
      <li>Complex discount schemes using custom built cart in React, coordinated with Shopify Scripts in Ruby.</li>
    </ul>
    
    <img src="/charmedaroma.jpg" />

    <h5>Permeal</h5>
    <p>
      A "solopreneur" SaaS startup. Permeal is designed to facilitate digital communication between nutrition practitioners and their clients.
      Permeal is a Rails + Backbone.js web application, with iOS and Android clients built using Ionic, on Cordova. Currently I'm considering next-step strategies, and planning to migrate to the Elixir/Phoenix, GraphQL, React Native stack.
    </p>
    <img src="/permeal.png" />

    <h5>celebrations.com</h5>
    <p>
      Celebrations.com, a subsidiary of 1-800-Flowers.com, acquired pingg. The sites were merged in September 2013. For one year after the acquisiton, I, along with the original pingg team, worked remotely. I worked as a full-stack developer consultant.
    </p>

    <h5>pingg</h5>
    <p>
      pingg was an online invitation management and greeting card marketplace platform built on Rails. Started in 2008, it had a large codebase, with legacy and modern parts. I worked as a full-stack web developer at the engineering office in Toronto, Ontario.
    </p>
    <img src="/pingg.jpg" />

    <h5>Polar Mobile</h5>
    <p>
      While Polar was still a mobile application platform for media companies, I worked as a project/product manager, where I lead a team and was responsible for the whole product delivery pipeline of the company.
    </p>
    <div style={{textAlign: "center"}}>
      <img src="/polar-mobile.png" />
    </div>
  </Layout>
)

export default MyWork
